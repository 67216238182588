import "core-js/modules/es.reflect.has.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.reflect.to-string-tag.js";
import { Toast } from '@utils/tool';
/**
 * @desc 页面公共hooks
 * */

export default function commMixins() {
  // 路由获取
  var route = useRoute(); // 获取params参数

  var getRouteParams = function getRouteParams() {
    return route.params;
  }; // 获取query参数


  var getRouteQuery = function getRouteQuery() {
    return route.query;
  }; // 判断对象中是否包含某个参数


  var isObjectParameter = function isObjectParameter(obj, name) {
    return Reflect.has(obj, name);
  }; // 检测query参数是否存在


  var urlQueryCheck = function urlQueryCheck(key, msg) {
    var query = getRouteQuery();

    if (query) {
      if (!isObjectParameter(query, key)) {
        Toast(msg);
        return false;
      }

      return true;
    } else {
      Toast('query参数为空');
      return true;
    }
  };

  return {
    getRouteParams: getRouteParams,
    getRouteQuery: getRouteQuery,
    isObjectParameter: isObjectParameter,
    urlQueryCheck: urlQueryCheck,
    Toast: Toast
  };
}