
/**
 * 设置 localStorage
 * @param {String} name 需要存储 localStorage 的 key
 * @param {String} value 需要存储 localStorage 的 value
 * * @intro 可以直接设置 json 而不是 json 串
 */
export const setLocal = (key:string, value: any) => {
	const item = getLocal(key)
	if (item) {
		removeLocal(key)
	}
	var data = {
		value: value
	}
	localStorage.setItem(key, JSON.stringify(data))
	
}

/**
 * @description 获取 localStorage
 * @param {String} name 需要获取 localStorage 的 key
 * @intro 返回 json 而不是 json 串
 */
export const getLocal = (key:string) => {
	var data = localStorage.getItem(key)
	if (!data || data === 'null') {
		return null
	}
	return JSON.parse(data).value
}

/**
 * @description 删除指定 localStorage
 * @param {String} name 需要删除 localStorage 的 key
 */
export const removeLocal = (key:string) => {
	localStorage.removeItem(key)
}

/**
 * @description 清空所有 localStorage
 */
export const clearLocal = () => {
	localStorage.clear()
}

export const tokenKey = 'token'
export const PUBLIC_TOKEN = 'PUBLIC_TOKEN' // 公众号token
export const userKey = 'memberId'
export const userDataKey = 'userDataKey'
export const roleIdKey = 'roleId'
export const shopIdKey = 'shopId'
