import { getCookie, setCookie } from './js-cookie'
import CryptoJS from 'crypto-js'
import Base64 from 'js-base64'
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { NODE_ENV } from '@/utils/envAttribute'
import { getLocal, tokenKey } from '@utils/localStorage'

// 是否undefined
export const isUndefined = val => String(val) === 'undefined'
// 是否 null
export const isNull = val => String(val) === 'null'
// 是否 Array
export const isArray = val => Object.prototype.toString.call(val) === '[object Array]'


// 按顺序获取123
export const getRandom = () => {
	if (!(window as any).imgRandom) {
	  (window as any).imgRandom = new Map()
	}
  
	let resNum: number
	let num = (window as any).imgRandom.get('img')
	if (num == 3) {
	  resNum = 1
	} else if (num == 1) {
	  resNum = 2
	} else if (num == 2) {
	  resNum = 3
	} else {
	  resNum = Math.ceil(Math.random() * 3)
	}
	(window as any).imgRandom.set('img', resNum)
	return resNum
  }
  // 获取token
  export function getToken() {
	let token = getCookie('token')
	return token
  }
  

// 价钱转换, 将价钱转换成以小数点分隔开的数组
export const priceToArr = price => {
	let arr = price.split('.')
	if (arr.length === 2) {
		arr.splice(1, 0, '00')
	}
	return arr[arr[2]]
}

/**
 * @description 获取 url 参数
 * @param {String} paraKey 需要获取参数的 key
 * @return {String} paraKey 对应的 value 值
 */
 export const getQuery = (paraKey: string) => {
	let href=location.href;
	let reg = new RegExp('(^|&)' + paraKey + '=([^&]*)(&|$)')
	let query=href.slice(href.indexOf('?'))
	let endIdx=query.indexOf('#')>0?query.indexOf('#'):undefined
	query=query.slice(0,endIdx)
	let r = query && query.slice(1).match(reg)
	return r && r.length > 2 ? decodeURIComponent(r[2]) : ''
  }

/**
 * 删除特定url的特定参数
 * @param {String} url 原始url
 * @param {String} key 需删除的参数名 如：'token'
 * @returns
 */
 export function deleteQueryString(url: string, key: string) {
    let baseUrl = url.split('?')[0] + '?';
    let query = url.split('?')[1];
    if (query.indexOf(key) > -1) {
      let obj = {};
      let arr: any[] = query.split('&');
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].split('=');
        obj[arr[i][0]] = arr[i][1];
      }
      delete obj[key];
      let url =
        baseUrl +
        JSON.stringify(obj)
          .replace(/["{}]/g, '')
          .replace(/:/g, '=')
          .replace(/,/g, '&');
      return url;
    } else {
      return url;
    }
  }

// 日期转换为 / 隔开
export const formateDateToSlash = dateTimer => {
	let reg = new RegExp('-', 'g')
	return dateTimer.replace(reg, '/')
}

/**
 * 检测链接是否是视频
 * @param {string} url
 * @returns {Boolean}
 */
export const isVideo = url => {
	let videoSuffix = ['MP4', 'WEBM', 'OGG', 'MOV', 'MPEG4']
	let suffix = url.slice(url.lastIndexOf('.') + 1, url.length)
	let videoType = !!videoSuffix.includes(suffix.toUpperCase())
	return videoType
}

/**
 * toFixed方法重写解决精度丢失，替换原型链上的方法
 */
type NumOrStr = number | string;
Number.prototype.toFixed = function (d: any) {
	var changeNum: NumOrStr = this + '';// 把数字转为字符串
	if (changeNum.indexOf('-') != -1)// 判断是否是负数
	{ changeNum = Math.abs(changeNum as any); }
	changeNum = (Math.round((changeNum as any) * Math.pow(10, d)) / Math.pow(10, d)).toString();

	var index = changeNum.indexOf('.');// 判断字符是否有小数点
	if (index == -1) { // 如果没有小数点，加上对应个数的小数点
		changeNum += '.';
		changeNum += new Array(d + 1).join('0');
	} else if (index > 0) { // 如果有，index是小数点的坐标值，比如在第二位index=1
		var temp = d - (changeNum.length - index - 1);// 小数点后的位数小于要取舍的个数，用0补齐；数字的长度-小数点的坐标值-1=小数的位数(小数点也占以为，4.00 length:4;.的index是1)
		if (temp > 0) {
			changeNum += new Array(temp + 1).join('0');
		}
	}
	if (this.toString().indexOf('-') != -1) { // 如果是负数，加上-
		changeNum = "-" + changeNum;
	}
	return changeNum;
}

/**
 * 价格格式化，返回整数部分和小数部分
 * @param price 需要格式化的价格
 * @param type 0:返回整数部分，1:返回小数部分 没有小数部分返回 00
 * @return String
 */
export const formatePriceIntAndFloat = (price, type) => {
	if (!price) { return }
	let priceStr = price.toString()
	let priceArr = priceStr.split('.')
	let result = priceArr[type]
	if (result) {
		result = result.length < 2 && type === 1 ? `${result}0` : result
	} else {
		result = '00'
	}
	return result
}


/**
 * 
 * @param date 
 * @param fmt 
 * @returns 
 */
export function dateFormat(date:Date,fmt='YYYY-mm-dd HH:MM:SS') {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (const k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}

/**
 *  设置页面title
 * @param title string
 */
 export function setPageTitle(title) {
  if (!title) {
    return;
  }

  //webview 设置title 
  document.title = title;
  const iframe = document.createElement('iframe');
  if (NODE_ENV !== 'dev') {
    try {
      const parent: any = window.parent;
      if (parent) {
        parent.document.title = title;
      }
    } catch (error) {
      console.log(error);
    }
  }
  document.body.appendChild(iframe);
  const timer = setTimeout(() => {
    document.body.removeChild(iframe);
    clearTimeout(timer);
  }, 500);
}

/**
 * 路由会跳统一处理
 */

 export function routerGuards(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext){
  
    const meta: any = to.meta;
    setPageTitle(meta.title);
    next(); //如果匹配到正确跳转
}


type device =
  | "isIOS"
  | "isAndroid"
  | "isJdApp"
  | "isHuawei"
  | "isJdcApp"
  | "isThe1App";
interface SysType {
  isIOS: boolean;
  isAndroid: boolean;
  isJdApp: boolean;
  isHuawei: boolean;
  isJdcApp: boolean;
  isThe1App: boolean;
}
export function getSysType(device: device): boolean | SysType {
  const ua = window.navigator.userAgent.toLowerCase();
  const result = {
    isIOS: Boolean(ua.indexOf("ipad") > -1 || ua.indexOf("iphone") > -1),
    isAndroid: Boolean(ua.indexOf("android") > -1),
    isJdApp: Boolean(ua.indexOf("t1w") > -1),
    isHuawei: Boolean(ua.indexOf("huawei") > -1),
    isJdcApp: Boolean(
      ua.indexOf("t1w") > -1 && ua.indexOf("dolfin_jdc_sdk") > -1
    ),
    isThe1App: Boolean(
      ua.indexOf("t1w") > -1 && ua.indexOf('"apptag":"the1"') > -1
    ),
  };
  return device ? result[device] : result;
}



/*
* 7500 --> 7,500 钱千分位修改
*
* @param {any} num
* @param {any} cent
* @returns
*/
export function thousandBitSeparator(num: any) {
 if (Number(num) < 0) {
	 return 0;
 }
 if (num) {
	 num = num.toString().replace(/\$|,/g, "");
	 if ("" == num || isNaN(num)) {
		 return "Not a Number ! ";
	 }
	 const sign = num.indexOf("-") > 0 ? "-" : "";
	 let cents = num.indexOf(".") > 0 ? num.substr(num.indexOf(".")) : "";
	 cents = cents.length > 1 ? cents : "";
	 num = num.indexOf(".") > 0 ? num.substring(0, num.indexOf(".")) : num;
	 if ("" == cents) {
		 if (num.length > 1 && "0" == num.substr(0, 1)) {
			 return "Not a Number ! ";
		 }
	 } else {
		 if (num.length > 1 && "0" == num.substr(0, 1)) {
			 return "Not a Number ! ";
		 }
	 }
	 for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
		 num =
			 num.substring(0, num.length - (4 * i + 3)) +
			 "," +
			 num.substring(num.length - (4 * i + 3));
	 }
	 return sign + num + cents;
 } else {
	 return num;
 }
}


 /**
 *
 * 对数字 保留两位小数
 * @returns
 */
export function returnFloat(val: string | number) {
  let value: string | number =
    Math.round(parseFloat((val || 0).toString()) * 100) / 100;
  const xsd = value.toString().split(".");
  if (xsd.length == 1) {
    value = value.toString() + ".00";
    return value;
  }
  if (xsd.length > 1) {
    if (xsd[1].length < 2) {
      value = value.toString() + "0";
    }
    return value;
  }
}

//日期转换
export function formatDate(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  let day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
  return `${year}-${month}-${day}`;
}

// 解析nickname
export function getDeCodeNickName(nickName) {
  if (!nickName) {
    return nickName
  }
  if (nickName.indexOf('%') > -1) {
    return getDeCodeNickName(decodeURI(nickName))
  } else {
    return nickName
  }
}

export function getDescData(data: any, header: any = {}) {
	let fid = 'ios-v1.0.0'
  let plantForm = 'ios'
  let fun = getIosEncrypted
  // getSysType('isAndroid')
	if (getSysType('isAndroid')) {
    plantForm = 'android'
		fid = 'android-v1.0.0'
    fun = getAndroidEncrypted
	} else {
    plantForm = 'ios'
		fid = 'ios-v1.0.0'
    fun = getIosEncrypted
	}
  const token = getLocal(tokenKey)
	// 具体描述说明 http://confluence.hivescm.com/pages/viewpage.action?pageId=9503780
	const requestHeader = {
		"os": "27",
		"fwToken": token,
		"channel": "test",
		// "version_code": keyVersion,
		// "mid":sysinfo.model,
		// "IMEI":"99001215612940",
		// "Accept-Encoding":"gzip",
		// "currentStoreGroupId":"20619",
		// "userId":userData.userAuthId,
		// "version":httpData.versionName,
		//"mac":"02:00:00:00:00:00",
		"platform": plantForm,
		"network":"WIFI",
		"currentStoreId":"942",
		"content_type":"json",
		"encrypt":"0",
		"appType":"wms",
		// "w":sysinfo.screenWidth,
		// "appid": "com.hivescm.wms.sorting",
		"guid": "8789968e08a61a07f8c5fe4f98eea7b7",
		"udid": "28c6f394-3ea9-3f9f-a391-217ea6f43a3a",
		// "brand":sysinfo.brand,
		"stationId":"26",
		...header
	}

	// data
	const requestBody = {
		header: requestHeader,
		body: data
	};
	
  console.log('requestBody', requestBody)
	let formData = {
		edata: fun(requestBody), //AES加密
		fid
	}

  return formData
}

function getIosEncrypted(data) {
  var key = "60bba43a00414949"; 
	var iv = "a8cba864fe4b9685"; 
	var CBCOptions = {
		iv: CryptoJS.enc.Utf8.parse(iv),
		mode:CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	};
	var aeskey = CryptoJS.enc.Utf8.parse(key);
		var secretData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
		var encrypted = CryptoJS.AES.encrypt(
		secretData, 
		aeskey, 
		CBCOptions
	);
  var encryptedBase64Str = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  console.log('encryptedBase64Str', encryptedBase64Str)
  return encodeURIComponent(encryptedBase64Str);
}

function getAndroidEncrypted(data) {
  const deskey = "2c8c69d98acf43759fe1335f0b566a39";
	const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Hex.parse(deskey), {
		mode: CryptoJS.mode.ECB,
	});
	const result = CryptoJS.enc.Hex.parse(encrypted.ciphertext.toString());
  console.log('encryptedBase64Str', result)
  return encodeURIComponent(CryptoJS.enc.Base64.stringify(result))
}

// function URLencode(sStr) {
//   return escape(sStr).replace(/\+/g, '%2B').replace(/"/g,'%22').replace(/'/g, '%27').replace(/\//g,'%2F');
// }