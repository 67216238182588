export default {
  setBonusPageLoading(state, data){
    state.bonusPageLoading = data
  },
  setDefaultBonusData(state, data) {
    state.defaultBonusData = {
      ...data
    }
  },
  setBonusListData(state,data){
    state.BonusListData={
      ...data
    }
  }
}