import { RouteRecordRaw } from 'vue-router'

const qrcodeRoutes: Array<RouteRecordRaw> = [
  {
    path: '/shortLink2miniprogram',
    name: 'shortLink2miniprogram',
    meta: {
      title: '短信跳转小程序',
      requireLogin: false
    },
    component: () => import('@/pages/shortLink2miniprogram/index.vue'),
  }
];

export default qrcodeRoutes