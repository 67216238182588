interface DeviceInfo {
  language: string
  [name: string]: string|number
}

const defaultDeviceInfo: DeviceInfo = {
  androidId: "56b4bf16bab8d696",
  androidUuid: "8e47432c-4ef8-4f49-8f8f-880315e22d5b",
  appChannel: "google",
  appTag: "dolfin",
  appUniqueCode: "8e47432c-4ef8-4f49-8f8f-880315e22d5b",
  appVersion: "2.9.0",
  appVersionCode: 3499,
  appsFlyerUID: "1611136576580-5085821507205490168",
  brand: "HUAWEI",
  clientName: "app",
  deviceModel: "ELE-AL00",
  deviceName: "MyPhone",
  deviceSequence: "unknown",
  deviceType: "APP",
  eid: "tak01I44476YVZ2RXI5EC2ZXH3NHTYUCFWKP3UKZRO3YEI7PBF75VMS65RPXPRHP7Y3DOLZFKWUXHY3IN3ZJKCL5KV2XXZGOKH6XNNMLCY6Q01234567",
  language: "zh_CN",
  location: "longtitude=116.559713#latitude=39.788898#baiDuLongtitude=null#baiDuLatitude=null#googleLongtitude=null#googleLatitude=null",
  networkType: "WIFI",
  openType: "1,0,0,0",
  os: "Android",
  osLanguage: "zh_CN_#Hans",
  osVersion: "10",
  pushChannel: "HMS",
  pushId: "IQAAAACy0Y5aAAApKw_xAWMKvJSH_iOTA2UcW3rcrSWAyUUXUZwkQVpt9zG-zWtasK29TBDJjTyfRnPYV5bnCF3aL2zcWt8F4MijyzjcfAcY7Rnz3w",
  webVersionCode: 3
};

const deviceInfo = (): Promise<DeviceInfo> => {
  return new Promise((resolve) => {
    (async function () {
      try {
        const curDeviceInfo = (JSON.parse(navigator.userAgent)) as DeviceInfo;
        resolve(curDeviceInfo || defaultDeviceInfo)
      } catch (error) {
        resolve({
          language: defaultDeviceInfo.language,
        })
      }
    })()
  })
};

export default deviceInfo
