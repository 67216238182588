import request,{post,get} from '@/services/request'
interface IData {
  [name: string]: any
}

/**
 * 获取bonus主页介绍数据
 */
 export function getMainBonusData(data: IData): Promise<IData> {
  return request('POST', '/cashier/query/method/list', data)
}

/**
 * 获取bonus主页列表数据
 */
 export function getBonusListData(data: IData): Promise<IData> {
  return request('POST', '/cashier/query/list', data)
}


/**
 * 唤起全局的jssdk
 */
 export function getJssdk(data: IData): Promise<IData> {
	return post({
		url:`/member/c/v2/receiveCardRecord/createWxSignatureInfo`,
		data,
		baseURL: ''
	})
}
