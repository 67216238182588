import { RouteRecordRaw } from 'vue-router'

const activityRoutes: Array<RouteRecordRaw> = [
  {
    path: '/activity/template/:customerId/:shopId/:storeId/:promotionId',
    name: 'activityTemplate',
    meta: {
      title: '活动页面',
      requireLogin: false
    },
    component: () => import('@/pages/activity/Template/index.vue'),
  }
];

export default activityRoutes