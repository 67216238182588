import { createApp } from 'vue'
import { Lazyload } from 'vant'
import { deviceInfo } from '@/utils'
import { getSysType, thousandBitSeparator, returnFloat } from '@/utils/base'
import { initSentry } from '@/utils/sentryInit'
import cdnUrls from '@/utils/cdnUrls'

import App from './App.vue'
import Skeleton from '@components/Skeleton/index.vue'
import router from './router'
import store from './store'
import "amfe-flexible";
import "@/assets/styles/reset.css";
import "@/assets/styles/common.scss";

async function init() {
  const curDeviceInfo = await deviceInfo();
  
  let source;
  if (getSysType('isAndroid')) {
    source = 'ANDROID'
  } else if (getSysType('isIOS')) {
    source = 'IOS'
  }
  const app = createApp(App);
  app.config.globalProperties.$cdnUrls = cdnUrls
  app.config.globalProperties.$deviceInfo = curDeviceInfo;
  app.config.globalProperties.$clientInfo = {
    lang: 'UTF-8',
    source,
  };
  app.config.globalProperties.$filters = {
    thousandBitSeparator,
    tofloat: returnFloat
  };
  app.config.errorHandler = (err, vm, info) => {
    console.log(err, err);
    console.log(err, vm);
    console.log(err, info);
  };
  initSentry({app,router,releaseName:'b2c-webapp'})
  app
    .use(store)
    .use(router)
    .use(Lazyload, {
      lazyComponent: true,
    })
    .component("bee-skeleton", Skeleton)
    .mount('#app')

  window.onload = function () {
    let origin = window.origin

    // 非生产环境引入调试工具
    if (origin !== 'https://mshop.newbeescm.com' && origin !== 'http://mshop.tfengscm.com') {
      import('vconsole').then((vConsole)=>{
        let vconsole = new vConsole.default()
      })
    }
  }
}

init();
