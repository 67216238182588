function getEnvAttribute (type) {
	if (process.env.NODE_ENV === 'development') {
		return "dev"
	} else if (process.env.NODE_ENV === 'test') {
		return "test"
	} else {
		return "prod"
	}
}
export const NODE_ENV = getEnvAttribute('NODE_ENV')
export const VUE_APP_ENV_CONFIG = process.env.VUE_APP_ENV_CONFIG

