import axios from 'axios';
import { Toast } from '@utils/tool';
import { miniJump } from '@/utils/routerHost'
import { headConfig } from '@/utils/appConfig';
import { dateFormat } from '@/utils/base';
import { submitSentryError } from "@/utils/sentryInit"

import { getLocal, removeLocal, tokenKey, userKey, PUBLIC_TOKEN} from '@utils/localStorage'
type method = 'POST' | 'GET'
interface IOptions {
  isLoading?: boolean
  origin?: string
  target?: string
  shopId?: string | number
  memberId?: string | number
  nickName?: string
  headers?: any
  // baseURL: string
}
interface IParams {
  url: string,
  data?: IData,
  target?: string,
  options?: any
  baseURL?: string,
}
const timeout = 15000;

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASEURL_API,
  timeout
});

const responseData = async (response, resolve, reject) => {
  const {data:res,status} = response
	const statusCode = res  && parseInt(res.status.statusCode)
	const expiredTokenCode = [10020, 10019, 301101]
  if (status == 200 && statusCode === 0 ) { // code 改成字符串, 但是不确定之前的接口 暂时不做类型校验
    resolve(res);
  }else if (expiredTokenCode.includes(statusCode)) {
    removeLocal(tokenKey);
    removeLocal(userKey);
    miniJump("miniLoginPage");
    return
  } else {
    const {url,headers,data} = response.config
    submitSentryError({
      url,
      headers,
      data:data,
      error:res
    });
    if(url.indexOf('actionPoint/gather') == -1){
      Toast(res.status.statusReason||"查询失败")
    }
    return resolve(res)
  }

};

const fetchH5 = (
  method: method,
  url: string,
  options: IOptions,
  data?: IData,
) => {
  return new Promise((resolve, reject) => {
    options.isLoading &&
      Toast.loading({
        duration: 0,
        message: 'Loading...',
        forbidClick: true,
      });
    if (url.includes('http:') || url.includes('https:')) {
      instance.defaults.baseURL = undefined
    }
    let requestPromise;
    const headers = {
      businessId: 1, // 预留信息
			token: getLocal(tokenKey),
			fwToken: getLocal(tokenKey),
      accessToken: getLocal(tokenKey),
      userId: localStorage.getItem("memberId"),
      ...options.headers
    };
    if (options.headers) {
      delete options.headers
    }
    if (options.target) {
      //api.do形式接口
      const requestTime = dateFormat(new Date());
      const jsonValue = {
        head: {
          target: options.target,
          client: headConfig.CLIENT,
          version: headConfig.VERSION,
          requestTime,
          channel: headConfig.CHANNEL,
          accessToken: getLocal(tokenKey),
          userId: localStorage.getItem("memberId")
        },
        data: data,
      }

      if (method === 'POST') {
        let data = `json=${encodeURI(JSON.stringify(jsonValue))}`
        data = data.replace(/\+/g, '%2B')
        requestPromise = instance.post(
          url,
          data,
          {...options,headers},

        );
      } else {
        requestPromise = instance.get(url, {
          params: {
            json: jsonValue,
          },
          ...options,
          headers
        });
      }
    } else {
      options = options || {}
      // restful接口
      if (method.toLocaleUpperCase() === 'POST') {
        requestPromise = instance.post(url, {...data }, { headers, ...options });
      } else {
        // 支持外部对axios的配置
        requestPromise = instance.get(url, { params: {...data }, headers, ...options });
      }
    }
    requestPromise
      .then(response => {
        options.isLoading && Toast.clear();
        
        responseData(response, resolve, reject);
      })
      .catch(error => {
        // Forced disconnection表示有意为之的强制中断
        if (error.message === 'Forced disconnection') {
          return
        }
        options.isLoading && Toast.clear();
        // 整合上报错误数据
        let errorData:IData = {...data }
        if (options.target) {
          errorData = {...errorData, target:options.target}
        }
        const errorParams={
          url,
          headers,
          data:errorData,
          error
        }
        submitSentryError(errorParams)
        // 对响应错误做点什么
        if(url.indexOf('actionPoint/gather') == -1){
          Toast(error);
        }
        reject(error);
      });
  })
};
const fetchH5_2 = (
  method: method,
  url: string,
  options: IOptions,
  data?: IData,
) => {
  return new Promise((resolve, reject) => {
    options.isLoading &&
      Toast.loading({
        duration: 0,
        message: 'Loading...',
        forbidClick: true,
      });
    let requestPromise;
    // "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFMyNTYiLCJ0eXAiOiJKV1QifQ.eyJzaG9wSWQiOjQ2MCwibWVtYmVySWQiOjE5MjAwNX0.0p3GfKQSITS1GF4qnoG8htqWB7YDqRwnW2LUBvDzV7Y"
    const headers = {
			token:  "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFMyNTYiLCJ0eXAiOiJKV1QifQ.eyJzaG9wSWQiOjQ2MCwibWVtYmVySWQiOjE5MjAwNX0.0p3GfKQSITS1GF4qnoG8htqWB7YDqRwnW2LUBvDzV7Y",
      userId: localStorage.getItem("memberId"),
    };
    options = options || {}
    // restful接口
    if (method.toLocaleUpperCase() === 'POST') {
      requestPromise = instance.post(url, {...data }, { headers, ...options });
    } else {
      // 支持外部对axios的配置
      requestPromise = instance.get(url, { params: {...data }, headers, ...options });
    }
    requestPromise
      .then(response => {
        options.isLoading && Toast.clear();
        
        responseData(response, resolve, reject);
      })
      .catch(error => {
        // Forced disconnection表示有意为之的强制中断
        if (error.message === 'Forced disconnection') {
          return
        }
        options.isLoading && Toast.clear();
        // 整合上报错误数据
        let errorData:IData = {...data }
        if (options.target) {
          errorData = {...errorData, target:options.target}
        }
        const errorParams={
          url,
          headers,
          data:errorData,
          error
        }
        submitSentryError(errorParams)
        // 对响应错误做点什么
        if(url.indexOf('actionPoint/gather') == -1){
          Toast(error);
        }
        reject(error);
      });
  })
};
const defaultOptions={ isLoading: false}
const request = (
  method: method,
  url: string,
  data?: IData,
  options?: IOptions
): Promise<any> => {
  return fetchH5(method, url, { ...defaultOptions, ...options}, data);
};
const request2 = (
  method: method,
  url: string,
  data?: IData,
  options?: IOptions
): Promise<any> => {
  return fetchH5_2(method, url, { ...defaultOptions, ...options}, data);
};
export const get = (params: IParams): Promise<any> => {
  const { url, data, ...options } = params
  return request('GET', url, data, options)
}
export const post = (params: IParams) => {
  const { url, data, options } = params
  return request('POST', url, data, options)
}
/**
 * 提现流程用get2 post2,get2 post2 用到的token是公众号号授权后获取的token，跟之前不一样 
 * @param params 
 * @returns 
 */
export const get2 = (params: IParams): Promise<any> => {
  const { url, data, ...options } = params
  return request2('GET', url, data, options)
}
export const post2 = (params: IParams) => {
  const { url, data, options } = params
  return request2('POST', url, data, options)
}
export default request
