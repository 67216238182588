
export default {
	// 首页
	index: {
		jumpType: 'reLaunch',
		getUrl: () => {
			return '/pages/index/index'
		}
	},

	// 首页
	home: {
		jumpType: 'reLaunch',
		getUrl: () => {
			return '/pages/index/index'
		}
	},

	
}
