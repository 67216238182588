
import wxPages from '@/utils/wxPage'
import webview from '@/utils/webview'
import qs from 'query-string'

// 需要跳转小程序publickLink页面
export const miniPublicLink = ['orderCancelDetail']

// 小程序跳转
export const miniJump = (pathName, query?:any) => {
	// 小程序端需要跳转到publickLink页面
	if (miniPublicLink.includes(pathName)) {
		let miniUrl = location.origin +  pathName + '?' + qs.stringify(query)
		webview[wxPages[pathName]['jumpType']](wxPages[pathName]['getUrl'](miniUrl))
	}
}
