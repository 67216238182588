import { RouteRecordRaw } from 'vue-router'

const arguementRoutes: Array<RouteRecordRaw> = [
  {
    path: '/arguement/afterSale',
    name: 'arguementAfterSale',
    meta: {
      title: '售后协议',
      requireLogin: false
    },
    component: () => import('@/pages/arguement/afterSale.vue'),
  }
];

export default arguementRoutes