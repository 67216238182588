import { RouteRecordRaw } from 'vue-router'

const qrcodeRoutes: Array<RouteRecordRaw> = [
  {
    path: '/qrcode/index',
    name: 'qrcode',
    meta: {
      title: '跳转小程序',
      requireLogin: false
    },
    component: () => import('@/pages/qrcode/index.vue'),
  }
];

export default qrcodeRoutes