const domain = process.env.VUE_APP_CDN_URL
const imgDomain = domain + 'src/images/fenglin/'
const imgWeapp = domain + 'src/images/MicroStore-uni/'
const tengxunDomain = 'https://fr-source-1254060329.cos.ap-beijing.myqcloud.com/'
export default {
  cellRight: imgDomain + 'cell-right.png',
  floorTabOn: imgWeapp + 'images/floor-tab-on.png',
  icOrderNum: imgWeapp + 'image/ic_order_num.png',
  icHideMore: imgWeapp + 'image/ic_hide_more.png',
  icShowMore: imgWeapp + 'image/ic_show_more.png',
  icNoOrder: imgWeapp + 'image/ic_no_order.png',
  sharingManage: imgDomain + 'sharingManage.png',
  exclusiveGroup: imgDomain + 'exclusive-group.png',
  statementOfAccount: imgDomain + 'statementOfAccount.png',
  orderManager: imgDomain + 'orderManager.png',
  promotionCode: imgDomain + 'promotionCode.png',
  iconInvivation: imgDomain + 'invivation.png',
  downloadPoster: imgDomain + 'downloadPoster.png',
  arrowsSolid: imgDomain + 'arrowsSolid.png',
  textDuizhangyaoqing: imgDomain + 'text-duizhangyaoqing.png',
  iconSearch: imgDomain + 'icon-search.png',
  iconDowload: imgDomain + 'icon-dowload.png',
  iconDowloadLarg: imgDomain + 'btn-dowload-larg.png',
  qrcode: imgDomain + 'qrcode.png',
  textFenxiangguanyaoqing: imgDomain + 'text-fenxiangguanyaoqing.png',
  btnLijiyaoqing: imgDomain + 'btn-lijiyaoqing.png',
  yaoqingbuzhou: imgDomain + 'yaoqingbuzhou.png',
  chengweifenxiangguan: imgDomain + 'chengweifenxiangguan.png',
  haibaoJieguo: tengxunDomain + 'weappfenglin/haibao-jieguo.png',
  yaoqingfenxiangguan: imgDomain + 'yaoqingfenxiangguan.png',
  miniEmpty: imgWeapp + 'images/empty.png',
  sharingOfficerAgreementUrl: 'https://static.tfengscm.com/src/official/views/industrySolutions/externalLinksDetails-B.html?groupOrg=2&docId=771007',
  reward_goldcoin: imgWeapp + 'images/reward_goldcoin.png',
}