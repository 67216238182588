import { getMainBonusData,getBonusListData, } from '../services/api/bonus'
export default {
  getMainBonusData({ commit }, data){
    
    return new Promise((resolve,reject)=>{
      commit('setBonusPageLoading', true)
      getMainBonusData(data).then(res=>{
        
        commit('setBonusPageLoading', false)
        if(res && res.resultCode ===1) {
          commit('setDefaultBonusData', res.resultData)
        }
        resolve(res)
      }).catch((err)=>{
        
        reject(err)
      })
    })
  },
  getBonusList({commit},data){
    return new Promise((resolve,reject)=>{
      getBonusListData(data).then(res=>{
        if(res && res.resultCode ===1) {
          commit('setBonusListData', res.resultData)
        }
        resolve(res)
      }).catch((err)=>{
        reject(err)
      })
    })
  }

}