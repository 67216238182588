import { createRouter, RouteRecordRaw, createWebHashHistory } from 'vue-router'
// import activityRoutes from './modules/activity'
import commMixins from '@hooks/commMixins'
import { cTracking } from '@/services/api/track'

// 一次性引入所有router模块
const modulesFiles = require.context('./modules', true, /\.ts$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const value = modulesFiles(modulePath)
  modules = modules.concat(value.default)
  return modules
}, [])

// mixins
let { isObjectParameter } = commMixins()

import { setLocal, tokenKey, userDataKey } from '@utils/localStorage'
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/:404(.*)*",
    redirect: "/",
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: '首页',
      requireLogin: false
    },
    component: () => import('@/pages/homePage/index.vue'),
  },
  ...modules
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title as string
  }
  let localOrgin = location.origin
  let hrefStr = window.location.pathname
  // 短信跳转小程序 后续域名会更新
  if(/\/h_/.test(hrefStr)){
    let mpId = hrefStr.split('h_')[1]
    window.location.href= `${localOrgin}/#/sms2miniProgram?id=${mpId}`
    return
  }
  // 本地测试需要注释该判断
  if (localOrgin.includes('localhost') ||
      /^((https?):\/\/)?mshop-test.newbeescm.com/.test(localOrgin) ||
      /^((https?):\/\/)?www.beescm.cn/.test(localOrgin) ||
      /^((https?):\/\/)?www-mshop-pre\.beehivescm.com$/.test(localOrgin)) {
    // 短信跳转小程序 后续域名会更新
    if(/\/t_/.test(hrefStr)){
      let mpId = hrefStr.split('t_')[1]
      window.location.href= `${localOrgin}/#/shortLink2miniprogram?id=${mpId}`
      return
    }
  }
  let query = to.query
  
  if (Object.keys(query).length > 0) {
    let isReplace = false
    if (isObjectParameter(query, 'token')) {
      setLocal(tokenKey, query.token as string)
      delete query.token
      isReplace = true
    }
    if (isObjectParameter(query, 'userData')) {
      let userData = JSON.parse(decodeURIComponent(query.userData as string))
      localStorage.setItem('shopId', userData.shopId)
      setLocal(userDataKey, userData)
      delete query.userData
      isReplace = true
    }
    if (isReplace) {
      next({ ...to, replace: true })
    } else {
      next()
    }
  } else {
    next();
  }
  cTracking({
    currentPagePath:to.path,
    sourcePagePath:from.path,
    optType:2
  })

});
export default router
