import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/reward/task/index',
    name: 'rewardTask',
    meta: {
      title: '奖励任务',
      requireLogin: false
    },
    component: () => import('@/pages/reward/task/index.vue'),
  },
  {
    path: '/reward/validatePhoneNumber/index',
    name: 'rewardValidatePhoneNumber',
    meta: {
      title: '验证手机号',
      requireLogin: false
    },
    component: () => import('@/pages/reward/validatePhoneNumber/index.vue'),
  },
  {
    path: '/reward/validatePhoneNumber/setPassword',
    name: 'setPassword',
    meta: {
      title: '设置提现密码',
      requireLogin: false
    },
    component: () => import('@/pages/reward/validatePhoneNumber/setPassword.vue'),
  },
  {
    path: '/reward/validatePhoneNumber/resetPassword',
    name: 'resetPassword',
    meta: {
      title: '设置提现密码',
      requireLogin: false
    },
    component: () => import('@/pages/reward/validatePhoneNumber/resetPassword.vue'),
  },
  {
    path: '/reward/account/index',
    name: 'account',
    meta: {
      title: '我的账户',
      requireLogin: false
    },
    component: () => import('@/pages/reward/account/index.vue'),
  },
  {
    path: '/reward/account/withdrawDetail',
    name: 'withdrawDetail',
    meta: {
      title: '提现明细',
      requireLogin: false
    },
    component: () => import('@/pages/reward/account/withdrawDetail.vue'),
  },
  {
    path: '/reward/account/detail',
    name: 'accountDetail',
    meta: {
      title: '账户明细',
      requireLogin: false
    },
    component: () => import('@/pages/reward/account/detail.vue'),
  },
];

export default routes
