import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/distribution/sharing/orderList',
    name: 'orderList',
    meta: {
      title: '订单',
      requireLogin: false
    },
    component: () => import('@/pages/distribution/sharing/orderList.vue'),
  },
  {
    path: '/distribution/sharing/apply',
    name: 'sharingApply',
    meta: {
      title: '成为分享官',
      requireLogin: false
    },
    component: () => import('@/pages/distribution/sharing/apply.vue'),
  },
  {
    path: '/distribution/sharing/invitation',
    name: 'sharingInvitation',
    meta: {
      title: '邀请分享官',
      requireLogin: false
    },
    component: () => import('@/pages/distribution/sharing/invitation.vue'),
  },
  {
    path: '/distribution/sharing/list',
    name: 'sharingManage',
    meta: {
      title: '分享官管理',
      requireLogin: false
    },
    component: () => import('@/pages/distribution/sharing/manage.vue'),
  },
  {
    path: '/distribution/sharing/home',
    name: 'distributionSharingIndex',
    meta: {
      title: '我是分享官',
      requireLogin: false
    },
    component: () => import('@pages/distribution/sharing/index.vue'),
  },
  {
    path: '/distribution/sharing/exclusiveGroup',
    name: 'exclusiveGroup',
    meta: {
      title: '加入专属群',
      requireLogin: false
    },
    component: () => import('@pages/distribution/sharing/exclusiveGroup.vue'),
  },
  {
    path: '/distribution/sharing/promotionCode',
    name: 'promotionCode',
    meta: {
      title: '推广码',
      requireLogin: false
    },
    component: () => import('@pages/distribution/sharing/promotionCode.vue'),
  },
  {
    path: '/distribution/sharing/statementOfAccount',
    name: 'statementOfAccount',
    meta: {
      title: '对账单',
      requireLogin: false
    },
    component: () => import('@/pages/distribution/sharing/statementOfAccount.vue'),
  }
];

export default routes
