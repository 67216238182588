import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/distribution/captain/home',
    name: 'distributionCaptainIndex',
    meta: {
      title: '我是队长',
      requireLogin: false
    },
    component: () => import('@/pages/distribution/captain/index.vue'),
  }, {
    path: '/distribution/captain/achievement',
    name: 'distributionCaptainAchievement',
    meta: {
      title: '队长业绩',
      requireLogin: false
    },
    component: () => import('@/pages/distribution/captain/achievement.vue'),
  }
];

export default routes
