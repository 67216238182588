
import qs from 'query-string'
import wx from 'weixin-js-sdk'
export default {
/**
 * 保留当前页面，跳转到应用内的某个页面
 *
 * @export
 * @param {*} url
 */
	navigateTo (url) {
		wx.miniProgram.navigateTo({
			url: url,
			fail: (error) => {
				console.error(error)
			}
		})
	},

	/**
 * 关闭当前页面，跳转到应用内的某个页面
 *
 * @export
 * @param {*} url
 */
	redirectTo (url) {
		console.log('url', url)
		wx.miniProgram.redirectTo({
			url: url,
			fail: (error) => {
				console.error(error)
			}
		})
	},

	/**
 * 跳转到 tabBar 页面，并关闭其他所有非 tabBar 页面
 *
 * @export
 * @param {*} url
 */
	switchTab (url) {
		wx.miniProgram.switchTab({
			url: url,
			fail: (error) => {
				console.error(error)
			}
		})
	},

	/**
 * 关闭所有页面，打开到应用内的某个页面
 *
 * @export
 * @param {*} url
 */
	reLaunch (url) {
		wx.miniProgram.reLaunch({
			url: url,
			fail: (error) => {
				console.error(error)
			}
		})
	},

	/**
 * 向小程序发送消息，会在特定时机（小程序后退、组件销毁、分享）触发组件的message事件
 *
 * @export
 * @param {*}
 */
	postMessageByMini (data) {
		wx.miniProgram.postMessage({
			data
		})
	},

	/**
 * 小程序webview传来的参数解析
 *
 * @export
 * @param {*}
 */
	parseMiniQuery (data) {
		return qs.parse(data.mini)
	}
}
