import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { VUE_APP_ENV_CONFIG } from "./envAttribute"
interface IArgs{
  app,
  router,
  releaseName:string
}

export const initSentry = (args:IArgs)=>{
  if (process.env.VUE_APP_ENV_CONFIG === 'production' || process.env.VUE_APP_ENV_CONFIG === 'test') {
    const { app, router, releaseName } = args
    Sentry.init({
      app,
      debug: false,
      attachStacktrace: true,
      release: `v_${releaseName}_0.0.1`,  // 错误日志对应版本号
      dsn: "https://55fb9995b94f493cb9f8b192fb75be08@monitor.beescm.cn/2",
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        }),
      ],
      initialScope: {},
      environment: VUE_APP_ENV_CONFIG,
      tracesSampleRate: 1.0
    });
    app.config.globalProperties.$sentry = Sentry
  }
}

/**
 * 自定义上报错误
 * @param param0 
 */
export function submitSentryError ( { url, headers, data, error,type='httpError' })  {
  try {
      Sentry.withScope((scope) => {
        scope.setTag("errorType", type)
        url && scope.setExtra("requestUrl", url)
        headers && scope.setExtra("headers", headers)
        data && scope.setExtra("data", data)
        Sentry.captureException(JSON.stringify(error))
      })
  } catch(e) {
     console.error(e)
  }
}