import { RouteRecordRaw } from 'vue-router'
const qrcodeRoutes: Array<RouteRecordRaw> = [
  {
    path: '/sms2miniprogram',
    name: 'sms2miniprogram',
    meta: {
      title: '短信跳转小程序',
      requireLogin: false
    },
    component: () => import('@/pages/sms2miniprogram/index.vue'),
  }
];
export default qrcodeRoutes